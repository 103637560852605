<template>
  <div class="upload-container">
    <el-button :style="{background:color,borderColor:color}" icon="el-icon-upload" size="small" type="primary" @click=" dialogVisible=true">
      上传图片
    </el-button>
    
    <Modal v-model="dialogVisible"  :closable="false"  scrollable  width="380">  
      <el-upload
        :multiple="true"
        name="upfile"
        :data="uploadData"
        :file-list="fileList"
        :show-file-list="true"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :before-upload="beforeUpload"
        class="editor-slide-upload"
        :action="uploadUrl" 
        list-type="picture-card"
      >
        <div style="display:flex;width:140px;margin-top:-2px;margin-right:15px;">
            <i class="iconfont icon-shangchuan1" style="color:#409EFF;font-size:22px;margin-right:5px;margin-left:20px;"></i>
            <el-link :underline="false"  >
                <span style="font-size:14px;margin-right:15px;color:#E6A23C">上传图片</span>
            </el-link>  
        </div>
      </el-upload>
      <div slot="footer" style="height:24px;">
          <Button type="primary" plain style="margin-right:25px;"  @click="dialogVisible=false">取消</Button>
          <Button type="primary"   @click="handleSubmit">确定</Button>
      </div>
      <div slot="header" style="height:10px;line-height:10px">
          <span style="font-weight:600;font-size:14px;color:#ccc">上传图片</span>
      </div>
    </Modal>
  </div>
</template>

<script>
 import { getDate } from '@/api/Select'

export default {
  name: 'EditorSlideUpload',
  props: {
    color: {
      type: String,
      default: '#1890ff'
    }
  },
  data() {
    return {
      dialogVisible: false,
      listObj: {},
      fileList: [],
      uploadData:{}, //上传数据
      uploadUrl:this.$store.state.baseUrl +'oss/fileUpload?username='+this.$cookies.get('v_username')+'&password='+this.$cookies.get('v_password'),
    }
  },
  methods: {
    checkAllSuccess() {
      return Object.keys(this.listObj).every(item => this.listObj[item].hasSuccess)
    },
    handleSubmit() {
      const arr = Object.keys(this.listObj).map(v => this.listObj[v])
      if (!this.checkAllSuccess()) {
        this.$message('请 . If there is a network problem, please refresh the page and upload again!')
        return
      }
      this.$emit('successCBK', arr)
      this.listObj = {}
      this.fileList = []
      this.dialogVisible = false
    },
    handleSuccess(response, file) {
      const uid = file.uid
      console.log( JSON.stringify(response))
      const objKeyArr = Object.keys(this.listObj)
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          this.listObj[objKeyArr[i]].url = response.result
          this.listObj[objKeyArr[i]].hasSuccess = true
          return
        }
      }
    },
    handleRemove(file) {
      const uid = file.uid
      const objKeyArr = Object.keys(this.listObj)
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          delete this.listObj[objKeyArr[i]]
          return
        }
      }
    },
    beforeUpload(file) {
      const _self = this
      const _URL = window.URL || window.webkitURL
      const fileName = file.uid
      this.listObj[fileName] = {}
      this.uploadData = {
          p_idseq:getDate('year'), 
          p_user:this.$cookies.get('v_username'),
          p_table:'',
      }
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = _URL.createObjectURL(file)
        img.onload = function() {
          _self.listObj[fileName] = { hasSuccess: false, uid: file.uid, width: this.width, height: this.height }
        }
        resolve(true)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.editor-slide-upload {
  margin-bottom: 20px;
  ::v-deep .el-upload--picture-card {
    width: 100%;
  }
}
</style>
